import { client } from "@utils/axios-utils";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { PasswordFormType } from "./validatePassword";

export const setPassword = (data: PasswordFormType) => {
  return client().put("v2/creators/auth/set-password", data);
};

export const useSetPasswordCreatorMutation = (
  options?: Partial<
    Omit<
      UseMutationOptions<
        AxiosResponse<any, unknown>,
        AxiosError<DefaultErrorResponse>,
        PasswordFormType
      >,
      "mutationKey" | "mutationFn"
    >
  >,
) => {
  return useMutation({
    mutationKey: ["creator", "set", "password"],
    mutationFn: setPassword,
    ...options,
  });
};
