import { client } from "@utils/axios-utils";
import { getItem } from "@utils/localStorage";
import * as Yup from "yup";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export const emailRegExp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export type OTPChannels = "email" | "sms";

export const EmailSchema = Yup.object()
  .shape({
    "g-recaptcha-response": Yup.string(),
    // type: Yup.mixed<OTPChannels>().oneOf(["email", "sms"]).required(),
    // phone_number: Yup.string(),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email address")
      .required("This field is required!"),
  })
  .default({
    email: getItem("email") ?? "",
    // phone_number: undefined,
    // type: "sms" as const,
    "g-recaptcha-response": "",
  });

export type EmailFormType = Yup.InferType<typeof EmailSchema>;

export interface SendEmailResponse {
  data: {
    created_at: UnixTime;
    expires_at: UnixTime;
    code?: string | undefined;
    password?: boolean;
  };
  requested_on: Date;
}

export const sendEmail = async (data: EmailFormType) => {
  const response = await client().post<
    SendEmailResponse,
    AxiosResponse<SendEmailResponse>,
    EmailFormType
  >(`v2/creators/auth/start`, data);

  return response.data;
};

export const SendEmailMutationKey = ["creator", "send", "email"];

export const useSendEmailMutation = (
  options?: Partial<
    Omit<
      UseMutationOptions<
        SendEmailResponse,
        DefaultErrorResponse,
        EmailFormType
      >,
      "mutationKey" | "mutationFn"
    >
  >,
) =>
  useMutation({
    mutationKey: SendEmailMutationKey,
    mutationFn: sendEmail,
    ...options,
  });
