import OtpInput from "./OneTimePassword";
import { Spinner } from "@nextui-org/spinner";
import {
  UserExistData,
  UserExistParams,
  checkUserExistMutationKey,
} from "@queries/checkUserExist";
import { useQueryClient } from "@tanstack/react-query";
import { getItem } from "@utils/sessionStorage";
import { AxiosResponse } from "axios";
import { Field, FieldProps, Form, FormikProps, FormikValues } from "formik";
import { motion } from "framer-motion";
import { useRef } from "react";
import ResendCode from "./ResendCode";
import { datadogRum } from "@datadog/browser-rum";
import Typography from "@components/Typography";

function EmailVerificationForm<T extends FormikValues = FormikValues>({
  setFieldValue,
  submitForm,
  isSubmitting,
  values,
  errors,
}: FormikProps<T>) {
  const submitRef = useRef<HTMLButtonElement>(null);
  const queryClient = useQueryClient();
  const userExistMutation = queryClient
    .getMutationCache()
    .find<AxiosResponse<UserExistData, unknown>, unknown, UserExistParams>({
      mutationKey: checkUserExistMutationKey,
      status: "success",
      predicate(mutation) {
        return mutation.state.variables.value === getItem("email");
      },
    });

  let userExist: boolean = false;

  if (userExistMutation) {
    userExist = userExistMutation.state.data?.data.data.user_in_system ?? false;
  }

  const title =
    values.type === "sms"
      ? "Enter the code sent to your phone to verify your account."
      : "Enter the code sent to your e-mail to verify your account.";

  const handleKeyDown = <T extends HTMLElement = HTMLButtonElement>(
    event: React.KeyboardEvent<T>,
  ) => {
    if (event.key === "Enter") {
      submitForm();
    }
  };

  const onResendClick = () => {
    datadogRum.addAction(`verification.resend`);
  };

  const resetForm = () => {
    setFieldValue("otp_code", "");
  };

  const otpCode = getItem("otpCode");
  return (
    <motion.div
      initial={{ scaleY: 0, originY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      className="shadow-card-grey relative mt-3 overflow-hidden rounded-lg bg-white px-4 py-3"
    >
      {isSubmitting && (
        <div className="absolute inset-0 z-50 flex flex-col items-center justify-center gap-2 bg-white">
          <Spinner size="sm" />
          <p className="text-xs font-medium text-gray-600">
            Validating code...
          </p>
        </div>
      )}
      <p className="text-center text-sm font-medium text-[#4D5761]">
        {title}
        <ResendCode
          defaultTime={values.type === "email" ? 30 : 0}
          className="cursor-pointer ps-2"
          onClick={onResendClick}
          resetForm={resetForm}
        />
      </p>
      <Form>
        <div className="mt-4">
          <Field
            name="otp_code"
            component={({
              field: { value, ...field },
              ...rest
            }: FieldProps) => (
              <OtpInput
                valueLength={6}
                {...field}
                value={value ?? ""}
                onKeyDown={handleKeyDown<HTMLInputElement>}
                onChange={(value) => {
                  setFieldValue("otp_code", value);
                  if (value.trim().length === 6)
                    setTimeout(() => submitForm(), 0);
                }}
                focusAfter={submitRef}
                {...rest}
              />
            )}
          />
        </div>
        <div className="mt-4 flex justify-between gap-2 min-[472px]:justify-center">
          {otpCode && (
            <Typography
              type="body"
              size="sm"
              weight="normal"
              className="text-[#6C737F]"
            >
              <p className="">Hint!, OTP code is {otpCode}</p>
            </Typography>
          )}
        </div>
      </Form>
    </motion.div>
  );
}

export default EmailVerificationForm;
