import { datadogRum } from "@datadog/browser-rum";
import {
  isSignUpResponse,
  useSendEmailOTPMutation,
  EmailOneTimePasswordFormType,
  EmailOneTimePasswordSchema,
  OTPResponse,
} from "@queries/creator/sendEmailOTP";
import { useSendEmailOTPMutation as useSendDistributorEmailOTPMutation } from "@queries/master_distributors/sendEmailOTP";
import { AxiosError } from "axios";
import { Formik, FormikHelpers } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "sonner";
import { setItem } from "@utils/localStorage";
import { setItem as setSessionItem } from "@utils/sessionStorage";
import EmailVerificationForm from "./EmailVerificationForm";
import { Roles } from "@enums/Roles";

const initialValues = EmailOneTimePasswordSchema.getDefault();

const EmailVerificationFormik: React.FC<{
  value: string;
  callback: (data: OTPResponse["data"]) => void;
  loginRole: Roles;
}> = ({ callback, value, loginRole }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const sendOTPMutation =
    loginRole === Roles["creator.admin"]
      ? useSendEmailOTPMutation
      : useSendDistributorEmailOTPMutation;

  const sendOTP = sendOTPMutation();

  const handleSubmit = async (
    value: EmailOneTimePasswordFormType,
    helpers: FormikHelpers<EmailOneTimePasswordFormType>,
  ) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("submit");

    datadogRum.addAction("verification.submit");
    datadogRum.addTiming("verification.submit");

    return sendOTP
      .mutateAsync({
        ...value,
        "g-recaptcha-response": token,
      })
      .then((response) => {
        if (response.status === 200) {
          datadogRum.addAction("verification.submit.successful");

          if (!isSignUpResponse(response.data)) {
            datadogRum.addAction("verification.submit.login");
            setItem("access_token", response.data.data.access_token);
            setItem("refresh_token", response.data.data.refresh_token);
          } else {
            datadogRum.addAction("verification.submit.sign-up");
            setSessionItem(
              "verification_auth_code",
              response.data.data.verification_auth_code,
            );
          }

          callback(response.data.data);
        }
      })
      .catch((error: AxiosError<DefaultErrorResponse>) => {
        datadogRum.addAction("verification.submit.failed", {
          status: error.response?.status ?? "500",
          message: error.message,
        });

        if (
          error.response?.status === 403 &&
          error.response?.data.error.code === "AUTH_WRONG_OTP_CODE"
        ) {
          toast.error("Wrong code. Please double-check and try again", {
            id: "invalid-otp",
          });
          helpers.setFieldValue("otp_code", "");
          helpers.setFieldTouched("otp_code", false);
        }

        if (error.response?.status === 409) {
          toast.error(
            "Oops! It looks like the email or phone number you used is already connected to another account.",
            { id: "user-already-exist" },
          );
          helpers.setFieldValue("otp_code", "");
          helpers.setFieldTouched("otp_code", false);
        }

        if (error.response?.data.error.messages) {
          helpers.setErrors(error.response?.data.error.messages);
        } else {
          toast.error(
            "Uh-oh! It seems like we're experiencing some hiccups on our end. Please give the page a quick refresh and try again.",
          );
        }
      });
  };

  return (
    <Formik<EmailOneTimePasswordFormType>
      initialValues={{
        ...initialValues,
        email: value,
      }}
      component={EmailVerificationForm}
      initialTouched={{ email: true }}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      validationSchema={EmailOneTimePasswordSchema}
    />
  );
};

export default EmailVerificationFormik;
