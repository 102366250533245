import { client } from "@utils/axios-utils";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { EmailFormType } from "./sendEmail";

const requestResetPassword = (data: EmailFormType) => {
  return client().post("v2/creators/auth/request-reset-password", data);
};

export const useRequestResetPasswordCreatorMutation = (
  options?: Partial<
    Omit<
      UseMutationOptions<
        AxiosResponse<any, unknown>,
        AxiosError<DefaultErrorResponse>,
        EmailFormType
      >,
      "mutationKey" | "mutationFn"
    >
  >,
) => {
  return useMutation({
    mutationKey: ["creator", "reset", "password"],
    mutationFn: requestResetPassword,
    ...options,
  });
};
