import { MyButton } from "@components/Button";
import { Input } from "@nextui-org/input";
import { ErrorMessage, Form, FormikProps } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import "react-phone-number-input/style.css";
import { EmailFormType } from "@queries/creator/sendEmail";
import { getItem } from "@utils/localStorage";
import { Radio, RadioGroup } from "@nextui-org/react";
import { Roles } from "@enums/Roles";

const EmailForm: React.FC<
  FormikProps<EmailFormType> & {
    timeout?: number;
    sessionExpired?: boolean;
    loginRole: Roles;
    onChangeLoginRole: (role: Roles) => void;
  }
> = ({
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  timeout,
  touched,
  values,
  errors,
  isValid,
  sessionExpired = false,
  loginRole,
  onChangeLoginRole,
}) => {
  const inputRef = useRef(null);

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      (inputRef.current as HTMLInputElement).focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (getItem("email") && !sessionExpired && isValid) {
      handleSubmit();
    }
  }, [handleSubmit, isValid, sessionExpired]);

  return (
    <Form className="text-center">
      <motion.div
        key="form-field"
        className="rounded-md border border-gray-100 bg-white px-3 py-4 text-left"
      >
        <RadioGroup
          classNames={{
            wrapper:
              "flex flex-nowrap flex-row items-center justify-start gap-4 sm:gap-8 min-width-max items-stretch mb-4",
            label: "text-sm font-medium text-[rgb(var(--media-gray-700))]",
          }}
          value={loginRole}
          onChange={(event) => {
            console.log(event.target.value);
            onChangeLoginRole(event.target.value as Roles);
          }}
        >
          <Radio
            classNames={{
              base: "grow !max-w-none w-[50%] ",
              label: "text-sm font-medium text-[rgb(var(--media-gray-700))]",
              control: "bg-transparent !bg-[rgb(var(--media-primary))]",
              wrapper:
                "border group-data-[selected=true]:border-[rgb(var(--media-primary))]",
            }}
            value={Roles["creator.admin"]}
          >
            Creator
          </Radio>
          <Radio
            classNames={{
              base: "grow !max-w-none w-[50%]",
              label: "text-sm font-medium text-[rgb(var(--media-gray-700))]",
              control: "bg-transparent !bg-[rgb(var(--media-primary))]",
              wrapper:
                "border group-data-[selected=true]:border-[rgb(var(--media-primary))]",
            }}
            value={Roles["md.admin"]}
          >
            Partner
          </Radio>
        </RadioGroup>

        <Input
          variant="bordered"
          onValueChange={(value) => {
            setFieldValue("email", value);
          }}
          id="email"
          size="lg"
          fullWidth
          radius="sm"
          onBlur={handleBlur}
          autoFocus
          classNames={{
            inputWrapper: "bg-white shadow-card-grey px-5 py-[0.88rem]",
            input: "font-normal text-sm placeholder-shown:text-[#6C737F]",
          }}
          baseRef={inputRef}
          name="email"
          value={values["email"]}
          isInvalid={
            touched["email"] && Boolean(typeof errors["email"] !== "undefined")
          }
          label="Enter your email address"
          labelPlacement="inside"
          errorMessage={errors["email"]}
          onKeyDown={handleKeyDown}
        />
      </motion.div>

      <MyButton
        color="primary"
        className="mt-5 py-[0.62rem]"
        data-dd-action-name="login.submit-button"
        fullWidth
        isDisabled={!!timeout}
        isLoading={isSubmitting || Boolean(timeout)}
        type="submit"
      >
        {timeout ? `Try in ${timeout}` : "Continue"}
      </MyButton>
    </Form>
  );
};

export default EmailForm;
