import { Formik } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { EmailFormType, EmailSchema } from "@queries/creator/sendEmail";
import EmailForm from "./EmailForm";
import useEmailForm, { UseEmailFormInputCallback } from "./useEmailForm";
import { getItem } from "@utils/sessionStorage";
import { Roles } from "@enums/Roles";

const initialValues = EmailSchema.getDefault();

const EmailFormik: React.FC<{
  callback: (values: UseEmailFormInputCallback) => void;
  sessionExpired?: boolean;
  loginRole: Roles;
  onChangeLoginRole: (role: Roles) => void;
}> = ({ callback, sessionExpired = false, loginRole, onChangeLoginRole }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { time, onSubmit } = useEmailForm({
    callback,
    executeRecaptcha,
    loginRole,
  });

  return (
    <Formik<EmailFormType>
      initialValues={Object.assign({}, initialValues, {
        email: getItem("email") ?? undefined,
      })}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      validationSchema={EmailSchema}
    >
      {(props) => (
        <EmailForm
          timeout={time}
          sessionExpired={sessionExpired}
          {...props}
          isValid={Boolean(executeRecaptcha) && props.isValid}
          loginRole={loginRole}
          onChangeLoginRole={onChangeLoginRole}
        />
      )}
    </Formik>
  );
};

export default EmailFormik;
