import {
  PasswordFormType,
  PasswordSchema,
} from "@queries/creator/validatePassword";
import { Formik, FormikHelpers } from "formik";
import { SetPasswordForm } from "./SetPasswordForm";
import { getItem } from "@utils/sessionStorage";
import { useSetPasswordCreatorMutation } from "@queries/creator/setPassword";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "sonner";
import { Roles } from "@enums/Roles";
import { useSetPasswordPartnerMutation } from "@queries/master_distributors/setPassword";

const initialValues = PasswordSchema.getDefault();

export const SetPasswordFormik: React.FC<{
  loginRole: Roles;
  onSuccess?: () => void;
}> = ({ loginRole, onSuccess }) => {
  const email = getItem("email");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const setPasswordMutation =
    loginRole === Roles["creator.admin"]
      ? useSetPasswordCreatorMutation
      : useSetPasswordPartnerMutation;

  const setPassword = setPasswordMutation();

  const handleSubmit = async (
    value: PasswordFormType,
    helpers: FormikHelpers<PasswordFormType>,
  ) => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }
    const recaptchaToken = await executeRecaptcha("submit");

    value.password = value.password.trim();

    setPassword
      .mutateAsync({ ...value, "g-recaptcha-response": recaptchaToken })
      .then(() => {
        onSuccess?.();
      })
      .catch((error: any) => {
        if (
          error.response?.data.error.code === "REQUEST_VALIDATION_ERROR" &&
          error.response?.data.error.messages
        ) {
          helpers.setErrors(error.response.data.error.messages);
        } else {
          toast.error("Error setting up your password");
          console.error("Error setting up password", error);
        }
      });
  };
  return (
    <Formik<PasswordFormType>
      initialValues={{
        ...initialValues,
        email,
      }}
      component={SetPasswordForm}
      initialTouched={{ email: true }}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      validationSchema={PasswordSchema}
    />
  );
};
