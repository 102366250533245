import { datadogRum } from "@datadog/browser-rum";
import { Roles } from "@enums/Roles";
import useTimer, { UseTimerOutput } from "@hooks/useTimer";
import {
  EmailFormType,
  SendEmailResponse,
  useSendEmailMutation,
} from "@queries/creator/sendEmail";
import { setItem } from "@utils/sessionStorage";
import setRedirectionTokenExpiration from "@utils/setRedirectionTokenExpiration";
import { FormikConfig } from "formik";
import { toast } from "sonner";
import { useSendEmailMutation as useSendDistributorEmailMutation } from "@queries/master_distributors/sendEmail";

export interface UseEmailFormInputCallback {
  email: string;
  code?: string | undefined;
  password?: boolean;
}

interface UseEmailFormInput {
  callback: (values: UseEmailFormInputCallback) => void;
  executeRecaptcha:
    | ((action?: string | undefined) => Promise<string>)
    | undefined;
}

interface UseEmailFormOutput extends Pick<UseTimerOutput, "time" | "restart"> {
  handleSubmit: (
    values: EmailFormType,
  ) => void | Promise<void | SendEmailResponse>;
  onSubmit: FormikConfig<EmailFormType>["onSubmit"];
}

const useEmailForm = ({
  executeRecaptcha,
  callback,
  loginRole,
}: UseEmailFormInput & {
  loginRole: Roles;
}): UseEmailFormOutput => {
  const { time, restart } = useTimer(0);
  const submitCreator = useSendEmailMutation({
    onError: (error) => {
      if (error?.error?.num_code === 63003) {
        return;
      }
    },
  });

  const submitDistributor = useSendDistributorEmailMutation({
    onError: (error) => {
      if (error?.error?.num_code === 63003) {
        return;
      }
    },
  });

  const submit =
    loginRole === Roles["creator.admin"] ? submitCreator : submitDistributor;

  const handleSubmit = async (values: EmailFormType) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      throw new Error("Execute recaptcha not yet available");
    }

    values.email = values.email.toLocaleLowerCase().trim();

    const token = await executeRecaptcha("submit");
    const data = {
      ...values,
      "g-recaptcha-response": token,
    };

    datadogRum.addAction("login.submit");
    datadogRum.addTiming("login.submit");

    return submit
      .mutateAsync(data)
      .then((res) => {
        datadogRum.setUser({
          email: values.email,
        });

        if (res) {
          datadogRum.addAction("login.submit.successful");
          setItem("email", values.email);

          /* setRedirectionTokenExpiration({
            expires_at: res.data.expires_at,
            path: "/login",
            search:
              typeof window !== "undefined" ? window?.location.search : "",
          }); */

          callback({
            email: values.email,
            code: res.data.code,
            password: res.data.password,
          });
        }

        return res;
      })
      .catch((error) => {
        datadogRum.addAction("login.submit.failed", {
          status: error.response.status ?? "500",
          message: error.message,
        });

        if (
          error.response.status === 400 &&
          error.response.data.error.code === "INVALID_ID"
        ) {
          setItem("email", values.email);

          callback({ email: values.email, code: undefined });

          return;
        }

        if (error.response?.status === 429) {
          const retryAt = Number(error.response.headers["retry-at"] ?? 30);

          toast.info(
            `Too many requests. Please wait and try again in ${retryAt} seconds.`,
          );

          restart(retryAt);
        }

        throw error;
      });
  };

  const onSubmit = async (values: EmailFormType) => {
    return handleSubmit(values).catch((error) => {
      if (error.response.data.error.messages) {
        const result = Object.values(error.response.data.error.messages).join(
          ". ",
        );
        toast.error(result);

        return;
      }

      if (error.response?.data.error.code) {
        toast.error(error.response?.data.error.code);
      }
    });
  };

  return {
    time,
    restart,
    onSubmit,
    handleSubmit,
  };
};

export default useEmailForm;
