import {
  PasswordFormType,
  PasswordSchema,
  useValidatePasswordCreatorMutation,
  ValidatePasswordResponse,
} from "@queries/creator/validatePassword";
import { Formik, FormikHelpers } from "formik";
import { PasswordForm } from "./PasswordForm";
import { getItem } from "@utils/sessionStorage";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "sonner";
import { Roles } from "@enums/Roles";
import { useValidatePasswordPartnerMutation } from "@queries/master_distributors/validatePassword";
import { setItem } from "@utils/localStorage";
import { useRequestResetPasswordCreatorMutation } from "@queries/creator/requestResetPassword";
import { useRequestResetPasswordPartnerMutation } from "@queries/master_distributors/requestResetPassword";

const initialValues = PasswordSchema.getDefault();

export const PasswordFormik: React.FC<{
  loginRole: Roles;
  onSuccess?: (data: ValidatePasswordResponse["data"]) => void;
}> = ({ loginRole, onSuccess }) => {
  const email = getItem("email");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const validatePasswordMutation =
    loginRole === Roles["creator.admin"]
      ? useValidatePasswordCreatorMutation
      : useValidatePasswordPartnerMutation;

  const requestResetPasswordMutation =
    loginRole === Roles["creator.admin"]
      ? useRequestResetPasswordCreatorMutation
      : useRequestResetPasswordPartnerMutation;

  const { mutateAsync: requestResetPassword } = requestResetPasswordMutation();

  const validatePassword = validatePasswordMutation();

  const handleSubmit = async (
    value: PasswordFormType,
    helpers: FormikHelpers<PasswordFormType>,
  ) => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }
    const recaptchaToken = await executeRecaptcha("submit");

    value.password = value.password.trim();

    validatePassword
      .mutateAsync({ ...value, "g-recaptcha-response": recaptchaToken })
      .then((response) => {
        setItem("access_token", response.data.data.access_token);
        setItem("refresh_token", response.data.data.refresh_token);

        onSuccess?.(response.data.data);
      })
      .catch((error: any) => {
        if (error.response?.data.error.messages) {
          helpers.setErrors(error.response.data.error.messages);
        } else {
          toast.error("Error validating password");
          console.error("Error validating password", error);
        }
      });
  };

  const handleRequestResetPassword = async () => {
    const email = getItem("email");

    if (!email) {
      console.error("Email address not provided");
      toast.error("Email address not provided");
      return;
    }

    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }
    const recaptchaToken = await executeRecaptcha("request_reset_password");

    requestResetPassword({
      email,
      "g-recaptcha-response": recaptchaToken,
    })
      .then(() => {
        toast.success(
          `Done! If the email address ${email} exists in our database, it will receive an email with the reset password information.`,
        );
      })
      .catch((error) => {
        console.error("Error requesting a password reset:", error);
        toast.error(
          `There has been an error sending a request to reset password for the email address ${email}`,
        );
      });
  };

  return (
    <Formik<PasswordFormType>
      initialValues={{
        ...initialValues,
        email,
      }}
      initialTouched={{ email: true }}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      validationSchema={PasswordSchema}
    >
      {(props) => (
        <PasswordForm
          {...props}
          onRequestResetPassword={handleRequestResetPassword}
        />
      )}
    </Formik>
  );
};
