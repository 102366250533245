"use client";

import EmailVerificationFormik from "@features/login/email-verification/EmailVerificationFormik";
import EmailFormik from "@features/login/email/EmailFormik";
import { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useAuth } from "@context/Auth";
import { OTPResponse, SignInOTPResponse } from "@queries/creator/sendEmailOTP";
import { useRouter } from "next/navigation";
import { Roles } from "@enums/Roles";
import { getItem as getLocalStorageItem } from "@utils/localStorage";
import { getItem, setItem } from "@utils/sessionStorage";
import { PasswordFormik } from "./password/PasswordFormik";
import { SetPasswordFormik } from "./set-password/SetPasswordFormik";
import { ValidatePasswordResponse } from "@queries/creator/validatePassword";

type LoginSteps =
  | "email"
  | "otp"
  | "password"
  | "set_password"
  | "forgot_password";

const Login: React.FC<{ callback?: VoidFunction }> = ({ callback }) => {
  const [loginRole, setLoginRole] = useState<Roles>(Roles["creator.admin"]);
  const [step, setStep] = useState<LoginSteps>("email");

  const [sessionData, setSessionData] = useState<
    SignInOTPResponse["data"] | null
  >(null);

  const { setSession } = useAuth();
  const router = useRouter();

  const [hasPassword, setHasPassword] = useState<boolean>(false);

  const localAccessToken = getLocalStorageItem("access_token");

  useEffect(() => {
    if (localAccessToken) {
      router.push("/");
    }
  }, [localAccessToken, router]);

  const renderStep = () => {
    if (step === "email") {
      return (
        <EmailFormik
          callback={(data) => {
            if (data.code) setItem("otpCode", data.code);
            if (data.password) {
              setHasPassword(true);
              setStep("password");
            } else {
              setStep("otp");
            }
          }}
          loginRole={loginRole}
          onChangeLoginRole={(role: Roles) => setLoginRole(role)}
        />
      );
    }

    if (step === "otp") {
      return (
        <EmailVerificationFormik
          value={getItem("email")!}
          callback={(data: OTPResponse["data"]) => {
            if ("access_token" in data && "refresh_token" in data) {
              setSessionData(data);
              setStep(hasPassword ? "password" : "set_password");
            }
          }}
          loginRole={loginRole}
        />
      );
    }

    if (step === "password") {
      return (
        <PasswordFormik
          loginRole={loginRole}
          onSuccess={(data: ValidatePasswordResponse["data"]) => {
            setSession(data);
            router.replace(
              new URLSearchParams(window.location.search).get("redirect") ??
                "/",
            );
            callback?.();
          }}
        />
      );
    }

    if (step === "set_password") {
      return (
        <SetPasswordFormik
          loginRole={loginRole}
          onSuccess={() => {
            if (sessionData) {
              setSession(sessionData);
              router.replace(
                new URLSearchParams(window.location.search).get("redirect") ??
                  "/",
              );
              callback?.();
            }
          }}
        />
      );
    }
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY!}
    >
      {renderStep()}
    </GoogleReCaptchaProvider>
  );
};

export default Login;
