import * as Yup from "yup";
import { emailRegExp } from "./sendEmail";
import { client } from "@utils/axios-utils";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

export const PasswordSchema = Yup.object()
  .shape({
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email address")
      .required()
      .nullable(),
    password: Yup.string().required(),
    "g-recaptcha-response": Yup.string(),
  })
  .default({ password: "" });

export type PasswordFormType = Yup.InferType<typeof PasswordSchema>;

export const validatePassword = (data: PasswordFormType) => {
  return client().post<ValidatePasswordResponse>(
    "v2/creators/auth/validate-password",
    data,
  );
};

export interface ValidatePasswordResponse {
  data: {
    access_token: string;
    refresh_token: string;
    email: string;
    first_name: string;
    last_name: string;
  };
}

export const useValidatePasswordCreatorMutation = (
  options?: Partial<
    Omit<
      UseMutationOptions<
        AxiosResponse<ValidatePasswordResponse, unknown>,
        AxiosError<DefaultErrorResponse>,
        PasswordFormType
      >,
      "mutationKey" | "mutationFn"
    >
  >,
) => {
  return useMutation({
    mutationKey: ["creator", "validate", "password"],
    mutationFn: validatePassword,
    ...options,
  });
};
