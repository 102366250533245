const checkSessionStorage = () => {
  if (typeof window !== "undefined" && window.sessionStorage) {
    return true;
  }

  return false;
};

export const enum SessionStorageItems {
  "email" = "email",
  "verification_auth_code" = "verification_auth_code",
  "otpCode" = "otpCode",
  "password" = "password",
}

export type SessionStorageItem = keyof typeof SessionStorageItems;

export const setItem = (name: SessionStorageItem, value: string): void => {
  try {
    if (checkSessionStorage()) {
      sessionStorage.setItem(name, value);
    }
  } catch (e) {
    console.error(
      "Error happened during setting item to sessionStorage",
      e,
      name,
      value,
    );
  }
};

export const getItem = (name: SessionStorageItem): string | null => {
  try {
    if (checkSessionStorage()) {
      return sessionStorage.getItem(name);
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to sessionStorage",
      e,
      name,
    );
  }

  return null;
};

export const getAll = (): object | null => {
  try {
    if (checkSessionStorage()) {
      return { ...sessionStorage };
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to sessionStorage",
      e,
      name,
    );
  }

  return null;
};

export const deleteItem = (name: SessionStorageItem): void => {
  try {
    if (checkSessionStorage()) {
      sessionStorage.removeItem(name);
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to sessionStorage",
      e,
      name,
    );
  }
};
