import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import * as Yup from "yup";
import { client } from "@utils/axios-utils";
import { getItem } from "@utils/sessionStorage";
import { emailRegExp } from "@queries/creator/sendEmail";

export const EmailOneTimePasswordSchema = Yup.object()
  .shape({
    "g-recaptcha-response": Yup.string(),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email address")
      .required()
      .nullable(),
    otp_code: Yup.string()
      .min(6, "Should be exactly 6 digits")
      .required("This field is required!")
      .nullable(),
    // phone_number: Yup.string().required().nullable(),
    // type: Yup.mixed().oneOf(["email", "sms"]),
  })
  .default({
    otp_code: "",
    "g-recaptcha-response": "",
    email: getItem("email") ?? "",
    // phone_number: getItem("phone_number") ?? null,
    // type: "sms" as const,
  });

export type EmailOneTimePasswordFormType = Yup.InferType<
  typeof EmailOneTimePasswordSchema
>;

type OTPData = {
  access_token: JWT;
  refresh_token: JWT;
  email: string;
  first_name: string;
  last_name: string;
  verification_auth_code: string;
};

type SignUpOTPResponse = {
  data: Pick<OTPData, "verification_auth_code">;
};

export type SignInOTPResponse = {
  data: Omit<OTPData, "verification_auth_code">;
};

export type OTPResponse = SignUpOTPResponse | SignInOTPResponse;

export const isSignUpResponse = (
  response: OTPResponse,
): response is SignUpOTPResponse =>
  Object.prototype.hasOwnProperty.call(response.data, "verification_auth_code");

export const sendEmailOTP = (data: EmailOneTimePasswordFormType) =>
  client().post<OTPResponse>("v2/creators/auth/validate", data);

export const useSendEmailOTPMutation = (
  options?: Partial<
    Omit<
      UseMutationOptions<
        AxiosResponse<OTPResponse, unknown>,
        AxiosError<DefaultErrorResponse>,
        EmailOneTimePasswordFormType
      >,
      "mutationKey" | "mutationFn"
    >
  >,
) =>
  useMutation({
    mutationKey: ["creator", "send", "email", "otp"],
    mutationFn: sendEmailOTP,
    ...options,
  });
