import { ChangeEvent, useMemo } from "react";

const RE_DIGIT = new RegExp(/^\d+$/);

const OtpInput: React.FC<{
  value: string;
  valueLength: number;
  onChange: (value: string) => void;
  focusAfter?: React.RefObject<HTMLElement>;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  name: string;
}> = ({ value, valueLength, onChange, onKeyDown, focusAfter }) => {
  const valueItems = useMemo(() => {
    return new Array(valueLength).fill("");
  }, [valueLength]);

  const focusToNextInput = (target: HTMLInputElement) => {
    const nextElementSibling = target.nextElementSibling as HTMLInputElement;

    if (nextElementSibling) {
      nextElementSibling.focus();
    } else {
      focusAfter?.current?.focus();
    }
  };
  const focusToPrevInput = (target: HTMLInputElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnChange = (event: ChangeEvent<HTMLInputElement>, idx: number) => {
    const target = event.target;
    let targetValue = target.value.replaceAll(/\W+/gi, "");
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement;

    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== "") {
      onChange(
        value.substring(0, idx) + targetValue + value.substring(idx + 1),
      );
    }

    targetValue = isTargetValueDigit ? targetValue : "";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    } else {
      onChange(
        value.substring(0, idx) + targetValue + value.substring(idx + 1),
      );
    }
  };

  const inputOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    onKeyDown?.(event);

    if (event.key === "ArrowRight" || event.key === "ArrowDown") {
      event.preventDefault();
      return focusToNextInput(target);
    }

    if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
      event.preventDefault();
      return focusToPrevInput(target);
    }

    target.setSelectionRange(0, target.value.length);

    if (event.key !== "Backspace" || target.value !== "") {
      return;
    }

    focusToPrevInput(target);
  };

  const inputOnFocus = (event: React.FocusEvent<Element, Element>) => {
    const target = event.target as HTMLInputElement;

    const prevInputEl = target.previousElementSibling as HTMLInputElement;

    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div className="otp-group flex justify-between gap-2 min-[472px]:justify-center">
      {valueItems.map((_, idx) => (
        <input
          autoFocus={idx === value.length}
          key={idx}
          type="text"
          inputMode="numeric"
          placeholder="-"
          autoComplete="one-time-code"
          pattern="\d{1}"
          className="otp-input xs:min-w-11 shadow-input h-12 min-w-8 max-w-12 rounded-lg border-1 border-[#4D5761] text-center text-xl font-medium text-[#111927] transition-colors placeholder-shown:border-[#D0D5DD] focus-visible:outline-none"
          onChange={(e: ChangeEvent<HTMLInputElement>) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
          value={value.at(idx) ?? ""}
        />
      ))}
    </div>
  );
};

export default OtpInput;
