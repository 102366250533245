import { Link } from "@nextui-org/link";
import { useSendEmailMutation } from "@queries/creator/sendEmail";
import { useSendEmailMutation as useSendDistributorEmailMutation } from "@queries/master_distributors/sendEmail";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import useTimer from "../../../hooks/useTimer";
import { getItem } from "../../../utils/localStorage";
import { useAuth } from "@context/Auth";
import { Roles } from "@enums/Roles";

const ResendCode: React.FC<{
  resetForm: () => void;
  onClick: () => void;
  title?: string;
  className?: string;
  defaultTime?: number;
}> = ({ resetForm, onClick, title = "Resend", className, defaultTime = 0 }) => {
  const email = getItem("email")!;
  const { role } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const { time, restart, play } = useTimer(defaultTime);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const submit = useSendEmailMutation({
    onSuccess() {
      restart(30);
    },
    onSettled() {
      setLoading(false);
    },
  });
  const submitDistributor = useSendDistributorEmailMutation({
    onSuccess() {
      restart(30);
    },
    onSettled() {
      setLoading(false);
    },
  });

  const handleClick = async () => {
    setLoading(true);

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");

      setLoading(false);

      return;
    }

    onClick();

    const token = await executeRecaptcha("reset");

    resetForm();

    const { mutateAsync } =
      role === Roles["creator.admin"] ? submit : submitDistributor;

    mutateAsync(
      Object.assign({}, { "g-recaptcha-response": token }, { email }),
    ).catch((error: AxiosError<DefaultErrorResponse>) => {
      if (
        error.response?.status === 429 &&
        error.response.data.error.code === "RATE_LIMIT_ERROR"
      ) {
        const retryAt = Number(error.response.headers["retry-at"] ?? 30);
        toast.info(
          `Too many requests. Please wait and try again in ${retryAt} seconds.`,
        );

        restart(retryAt);
      } else if (
        error.response?.data.error.code === "FORBIDDEN" &&
        error.response.data.error.num_code === 60102
      ) {
        toast.error(
          "Captcha not valid. Please prove that you are not a robot!",
        );
      } else {
        toast.error(error.response?.data.error.code);
      }
    });
  };

  useEffect(() => {
    if (time > 0) {
      play();
    }
  }, [time, play]);

  return (
    <Link
      className={twMerge("inline text-sm font-medium", className)}
      color="secondary"
      isDisabled={Boolean(time) || submit.isPending || loading}
      onClick={handleClick}
    >
      {title} {Boolean(time) && `(${time})`}
      {loading && <span className="dot-flashing"></span>}
    </Link>
  );
};

export default ResendCode;
