import { MyButton } from "@components/Button";
import Typography from "@components/Typography";
import { Input } from "@nextui-org/react";
import { PasswordFormType } from "@queries/creator/validatePassword";
import { Form, FormikProps } from "formik";
import { motion } from "framer-motion";

export const SetPasswordForm: React.FC<FormikProps<PasswordFormType>> = ({
  setFieldValue,
  isSubmitting,
  touched,
  errors,
}) => {
  return (
    <Form className="text-center">
      <motion.div
        initial={{ scaleY: 0, originY: 0 }}
        animate={{ scaleY: 1 }}
        exit={{ scaleY: 0 }}
        className="rounded-md border border-gray-100 bg-white px-3 py-4 text-left"
      >
        <Typography type="body" size="sm" className="pb-2 text-left">
          Choose a safe password, it will be used for your future log-ins.
        </Typography>
        <Input
          type="password"
          id="password"
          name="password"
          size="lg"
          variant="bordered"
          fullWidth
          radius="sm"
          label="Choose your password"
          autoFocus
          classNames={{
            inputWrapper: "bg-white shadow-card-grey px-5 py-[0.88rem]",
            input: "font-normal text-sm placeholder-shown:text-[#6C737F]",
          }}
          labelPlacement="inside"
          onValueChange={(value) => {
            setFieldValue("password", value);
          }}
          isInvalid={
            touched["password"] &&
            Boolean(typeof errors["password"] !== "undefined")
          }
          errorMessage={errors["password"]}
        />
      </motion.div>

      <MyButton
        color="primary"
        className="mt-5 py-[0.62rem]"
        data-dd-action-name="login.submit-button"
        fullWidth
        type="submit"
        isDisabled={isSubmitting}
      >
        {"Submit"}
      </MyButton>
    </Form>
  );
};
